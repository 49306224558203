import React from "react";
// Import the PDF if it's part of your project (in the `src` folder)
import samplePDF from "./assets/NPTEL24HS160S95090334804145986.pdf";

const App = () => {
  const handleRedirect = () => {
    // Open the PDF document
    window.open(samplePDF, ""); // Opens the PDF in a new tab
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        backgroundColor: "#fff",
      }}
    >
      <button
        onClick={handleRedirect}
        style={{
          width: "195px",
          padding: "10px 30px",
          fontSize: "16px",
          fontWeight: "500.27",
          color: "#ffffff",
          backgroundColor: "#416FB9",
          fontFamily: "'Times New Roman', Times, serif",
          border: "none",
          borderRadius: "7px",
          cursor: "pointer",
        }}
      >
        Course Certificate
      </button>
    </div>
  );
};

export default App;
